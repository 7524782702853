import React, { Component } from 'react'
import AdsList from './AdsList'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'

class Jobs extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  
  render() {
    const { projects, auth} = this.props;
    if (!auth.uid) return <Redirect to='/inloggen' />

    return (
      <div className="page-container">
        <div className="centered">
          <AdsList projects= {projects} path={"/advertentie"} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.firestore.ordered.projects,
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'projects', 
    where: [
      ['category', '==', 'werk'], 
      ['approved', '==', true] 
    ],
     orderBy: ['createdAt', 'desc'],
     }
  ])
)(Jobs)