import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
//COMPONENTS
import ContactUser from '../ads/ContactUser'
//MUI
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MessageIcon from '@material-ui/icons/Message';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: '75%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    content: {
      flex: '1 0 auto',
    },
    coverContainer: { //center vertically
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(2),
    },
    cover: {
      width: 140,
      height: 140,
      margin: theme.spacing(2, 'auto'),
      marginRght: "5%"
    },
    button: {
      width: 150,
      marginTop: theme.spacing(1),
    },
    typography: {
      marginBottom: theme.spacing(1),
    }
  }));

function SearchHit ({hit}) {

    const classes = useStyles();
    const [isContactShown, setContactShown] = useState(false);
    const { t } = useTranslation();


    const handleContact = () => {
      isContactShown ? setContactShown(false) : setContactShown(true)
    }

  return (        
    <Card className={classes.root}>
      <div className={classes.coverContainer}>
        <CardMedia 
        className={classes.cover} 
        image={hit.picture || '../img/maskable_icon.png'} 
        title={hit.name}
        />
      </div> 
      <div className={classes.details}>
        <CardContent>
          <Typography variant="h6" component="h2" className={classes.typography}>{hit.name} 
          </Typography>
          <Typography variant="subtitle1" component="h6" className={classes.typography}>{t('beroep')}: {hit.work}
          </Typography>
          <Typography variant="subtitle1" component="h6" className={classes.typography}>{t('hobbies')}: {hit.hobby1}
          </Typography>
          <Typography variant="subtitle1" component="h6" className={classes.typography}>{t('woonplaats')}: {hit.city}
          </Typography>
          {isContactShown && <ContactUser props={hit}/>} 
          <Button
            className={classes.button}
            color={isContactShown ? "primary" : "secondary"} 
            variant="outlined" 
            startIcon={isContactShown ? <CloseIcon/> : <MessageIcon/>}
            onClick={handleContact}
          >
            {isContactShown ? t('annuleren') : t('bericht')}
          </Button> 
        </CardContent>
      </div>
    </Card>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default connect(mapStateToProps) (SearchHit)
