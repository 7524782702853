import React, { useState } from 'react';
import { connect } from 'react-redux';
// ACTIONS
import { sendMessage } from '../../store/actions/projectActions';
import { verifyEmail } from '../../store/actions/authActions';
// MUI
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';

// imbedded in: SearchHits, ApprovedAdDetails dashboard/ProjectDetails and MessageSummary
const ContactUser = ({ props, auth, verifyEmail, sendMessage }) => {
  const [messageData, setMessageData] = useState({
    message: '',
    messageSubject: 'Bericht van gebruiker',
    receiverId: props.authorId || props.objectID || props.objectID,
    receiverEmail: props.email || props.authorEmail,
    messageSent: 'Type uw bericht',
  });

  const { t } = useTranslation();

  const [verificationSend, setVerificationSend] = useState(false);

  const { message, messageSent } = messageData;

  const handleVerification = () => {
    verifyEmail();
    setVerificationSend(true);
  }

  const handleChange = (e) => {
    setMessageData({
      ...messageData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim() !== '') {
      sendMessage(messageData);
      setMessageData({
        ...messageData,
        message: '',
        messageSent: 'Uw bericht is verstuurd',
      });
    }
  };

  if (!auth.emailVerified) {
    return (
      <div className="centered" 
        style={{border: '1px solid #AE1C28', margin: '20px', padding: '20px'}}>
        <p className='centered'>{t('email is nog niet geverifieerd')}</p>
        <div className='flex-container2'>
          <Button 
            color="secondary"
            className="centered" 
            variant="outlined"
            onClick={handleVerification}
            disabled={verificationSend}
            style={{margin:'10px 10px'}}
          >
            {t('verifieer email')}
          </Button>
          {verificationSend && (
            <FormHelperText>{t('email met instructies is verstuurd')}</FormHelperText>
          )}
        </div>
        
      </div>
    );
  }  

  return (
    <form onSubmit={handleSubmit}>
      <TextareaAutosize
        style={{ display: 'block', margin: '20px auto' }}
        className="text-area"
        type="text"
        id="message"
        label="beschrijving"
        onChange={handleChange}
        aria-label="minimum height"
        minRows={7}
        placeholder={messageSent}
        value={message}
        readOnly={messageSent === 'Uw bericht is verstuurd'}
      />
      <Button
        style={{ display: 'block', margin: 'auto' }}
        type="submit"
        variant="contained"
        color="secondary"
        disabled={message.trim() === ''}
      >
        {t('versturen')}
      </Button>
      <FormHelperText style={{ textAlign: 'center' }}>{messageSent}</FormHelperText>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMessage: (message) => dispatch(sendMessage(message)),
    verifyEmail: () => dispatch(verifyEmail())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUser);
