import React from 'react'

const SplashScreen = () => {
  return (
    <div className="splash-container">
      <img className="splash-image" src='/img/logo192.png' alt="Egynl"/>
    </div>
  )
}

export default SplashScreen
