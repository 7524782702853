import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) //language will be saved after refresh?
  .init({
    interpolation: {
      escapeValue: false //react safes from xss
    },
    lng: "nl",
    fallbackLng: "nl",
    resources: {
      nl: {
        translation: {
            "intro1": "EGYNL is een online community voor Egyptenaren in Nederland. We streven er naar om mensen in contact te brengen.",
            "intro2": "Door een account aan te maken kunt u advertenties plaatsen, de advertenties van anderen bekijken en andere leden opzoeken.",
            "intro3": "Wij bieden verschillende services aan met onder andere: een platform voor services, werk, nieuws, hulpverlening en kennismaking bijeenkomsten.",
            "welkom": "Welkom ",
            "werk": "Werk",
            "markt": "Markt",
            "nieuws": "Nieuws",
            "inloggen": "Inloggen",
            "aanmelden": "Aanmelden",
            "uitloggen": "Uitloggen",
            "profiel": "Profiel",
            "advertentie plaatsen": "Advertentie plaatsen",
            "advertenties": "Advertenties",
            "marktplaats": "Marktplaats",
            "contact": "Contact",
            "overons": "Over ons",
            "voorwaarden": "Voorwaarden",
            "home": "Home",
            "zoeken": "Zoeken",
            "inbox": "Inbox",
            "mijn advertenties": "Mijn advertenties",
            "registreren": "Registreren",
            "email": "Email",
            "wachtwoord": "wachtwoord",
            "naam": "Volledig naam",
            "naam kort": "Naam",
            "met de gebruiksvoorwaarden": "Ik ga akkoord met de gebruiksvoorwaarden",
            "heeft u al een account": "heeft u al een account? login",
            "hier": "hier",
            "hier2": "hier",
            "nog geen account": "Nog geen account? Meld u aan ",
            "wachtwoord vergeten": "Wachtwoord vergeten?",
            "reset uw wachtwoord": "Reset uw wachtwoord",
            "contact-1": "Voor vragen en opmerkingen kunt u contact opnemen met: ",
            "contact-3": " Of maak gebruik van de contact formulier, als u ingelogd bent.",
            "contact-2": "Login om gebruik te maken van de contact formulier.",
            "categorie": "Categorie",
            "suggesties": "Vragen",
            "klachten": "Klachten",
            "overige": "Overige",
            "kies een categorie": "Kies een categorie",
            "onderwerp": "Onderwerp",
            "stuur uw bericht": "Verstuur uw bericht",
            "Uw bericht": "Uw bericht",
            "versturen": "Versturen",
            "overons title": "EGYNL is een initiatief van een groep Egyptenaren uit Amsterdam om een online community voor Egyptenaren in Nederland te starten.",
            "overons intro1": "Het platform onderscheidt zich door de nadruk te leggen op privacy; uw emailadres en tel nr. worden niet gedeeld, en actieve moderatie; alle advertenties worden gecontroleerd door onze moderators",
            "overons 1": "Door een account aan te maken kunt u advertenties plaatsen, de advertenties van anderen bekijken en andere leden opzoeken. Ook bent u vindbaar voor andere leden. Advertenties o.a. over: ",
            "overons li1": "Verkoop van fysieke goederen.",
            "overons li2": "Services aanbieden, en aanbevelingen.",
            "overons li3": "Nieuws van Egyptenaren in Nederland, of nieuws die van belang is.",
            "overons li4": "Advies vragen van andere leden.",
            "overons li5": "",
            "overons li6": "",
            "overons 2": "",
            "overons 3": "",
            "overons 4": "",
            "overons 5": "",
            "overons 6": "",
            "overons 7": "",
            "overons end": "",
            "Advertentie plaatsen": "Advertentie plaatsen",
            "voeg een foto": "Voeg een foto toe",
            "sleep een foto": "Klik hier of sleep een foto.",
            "een foto mag": "Een foto mag niet groter dan 5 MB zijn.",
            "uw foto": "Uw foto",
            "verkoop": "Verkoop",
            "beschrijving": "Beschrijving",
            "item-title": "Title",
            "plaatsen": "Plaatsen",
            "berichten": "Berichten",
            "annuleren": "Annuleren",
            "beantwoorden": "Beantwoorden",
            "verwijderen": "Verwijderen",
            "geplaatst door": "Geplaatst door",
            'mijn profiel': 'Mijn profiel',
            "beroep": "Beroep",
            "hobbies": "Hobbies",
            "woonplaats": "Woonplaats",
            "bericht": "Bericht",
            "telefoonnummer": "Telefoonnummer",
            "postcode": "Postcode",
            "account verwijderen": "Account verwijderen",
            "account verwijderen?": "Account verwijderen?",
            "account verwijderen dialog": "Vul uw wachtwoord in. Uw advertenties en berichten zullen worden verwijderd.",
            "vul interesses": "Vul uw interesses in, gescheiden door een comma.",
            'opslaan': 'Opslaan',
            "email notificaties": "Email notificaties ontvangen",
            'bericht van': 'Bericht van',
            'advertentie niet gevonden': 'Advertentie niet gevonden. Ga terug naar de',
            'email is nog niet geverifieerd': 'Email is nog niet geverifieerd',
            'email met instructies is verstuurd': 'een email met instructies is verstuurd, ververs pagina na verificatie',
            'verifieer email': 'Verifieer email',
            'zoeken2': 'Zoek andere leden van EgyNL. U kunt zoeken op naam, beroep, hobbie en woonplaats. U kunt andere gebruikers berichten sturen, uw Emailadres is niet zichtbaar voor anderen.',
            'zoeken3': 'Probeer ook in het Arabisch te zoeken.',


        }
      },
      ar: {
        translation: {
            "intro1": "EGYNL هو سجل عبر الإنترنت للمصريين في هولندا.",
            "intro2": 'من خلال إنشاء حساب يمكنك نشر الإعلانات وعرض إعلانات المستخدمين الآخرين والبحث عن أعضاء.',
            "intro3": 'نقدم خدمات متنوعة منها: منصة للخدمات والعمل والأخبار والمساعدة واللقاءات التعريفية.',
            "welkom": " أهلا بك ",
            "werk": "عمل",
            "markt": "سوق",
            "nieuws": "أخبار",
            "inloggen": "تسجيل الدخول",
            "aanmelden": "اشتراك",
            "uitloggen": "تسجيل خروج",
            "profiel": "الملف الشخصي",
            "advertentie plaatsen": "ضع إعلانا",
            "advertenties": "إعلانات",
            "marktplaats": "سوق",
            "contact": "اتصل",
            "overons": "من نحن",
            "voorwaarden": "شروط الخدمة",
            "home": "الرئيسية",
            "zoeken": "بحث",
            "inbox":"صندوق الوارد", 
            "mijn advertenties": "إعلاناتي",
            "registreren": "تسجيل",
            "email":"البريد الإلكتروني",
            "wachtwoord": "كلمه السر",
            "naam": "الاسم الكامل",
            "naam kort": "الاسم",
            "met de gebruiksvoorwaarden": " أنا أتفق مع  شروط الاستخدام ",
            "heeft u al een account": "لديك حساب؟",
            "hier": "تسجيل الدخول",
            "hier2": " هنا ",
            "nog geen account": " لا يوجد حساب بعد؟ سجل ",
            "wachtwoord vergeten": "هل نسيت كلمه السر؟",
            "reset uw wachtwoord": "اعد ضبط كلمه السر",
            "contact-1": "للتعليقات والأسئلة يرجى الاتصال عن طريق: ",
            "contact-3": " او استخدم نموذج التواصل, بعد تسجيل الدخول .",
            "contact-2": "استخدم النموذج بعد تسجيل الدخول.",
            "categorie": "فئة",
            "suggesties": "نصيحة",
            "klachten": "شكاوي",
            "overige": "الآخرين",
            "kies een categorie": "اختر فئة",
            "Uw Email": "البريد الإلكتروني",
            "onderwerp": "موضوع",
            "stuur uw bericht": "أرسل رسالتك",
            "Uw bericht": "رسالتك",
            "versturen": "إرسال",
            "overons title": "أخوتي وأخواتي",
            "overons intro1": "إتفقت  مجموعة من المصريين المقيمين  في أمستردام على تقديم  خدمة للمصريين في كل المحافظات الهولندية وذلك من خلال وضع سجل لكل من يرغب من المصريين و المصريات و عائلاتهم وفي هذا السجل تكتب بيانات  تشمل معلومات عن العضو ( مثل الفيس بوك)أو(تويتر) وطبيعي ليس فيها المعلومات الشخصية هذه الملومات تشمل :الاسم (ممكن مستعار)  - مجال العمل - رقم التليفون - الايميل - الهواية - المجال الذي يرغب التسجيل به",
            "overons 1": "أولا: من خلال هذا السجل سيكون بمقدور كل عضو  الدخول الى ملف كل عضو  يرغب في التعامل معه وذلك عن طريق التواصل سواء تليفونيا أو على الإيميل أو على الميسنجر والفيس بوك ويكون الهدف من التواصل هوفي",
            "overons li1": "الأصلاحات الفنية",
            "overons li2": "المساعدة الطبية",
            "overons li3": "المساعدة القانونية",
            "overons li4": "المساعدة في الإستشارات المادية (البنوك)",
            "overons li5": "المساعدة في السياحة مثل العمرة والحج",
            "overons li6": "ألخ ألخ ،وما يستجد من مجالات أخرى في كل الإتجاهات الممكنة",
            "overons 2": "ثانيا : بيع الممتلكات او بيع أجهزة أو مختلف ما يستغني عنه العضو",
            "overons 3": "ثالثا : الإعلان عن بيع تجاري",
            "overons 4": "رابعا : تحرير شكاوى عن أى تصرف سيء من أحد الأعضاء.",
            "overons 5": "خامسا : الإجابة على تساؤلات عن أحد المواضيع .",
            "overons 6": "سادسا : كتابة مقالة صغيرة عن موضوع أو تجربة يرغب العضو في أن يشاركه الأعضاء في هذا الموضوع (موضوع داخل هولندا أو يخص مصريين في هولندا).",
            "overons 7": "سابعا : أخبار المصريين في هولندا عن الأعضاء مثلا لو لديه حالة زواج أواحتفال بمناسبة أو عيد ميلاد أو عملية جراحية في مستشفى أوحالة وفاة ، إلخ إلخ.",
            "overons end": "وغيره من الممكن لنتعاون جميعا في كل المجالات الممكنة والتي يمكن أن تستجد في المستقبل",
            "Advertentie plaatsen": "إضافة الاعلان",
            "voeg een foto": "إضافة صورة.",
            "sleep een foto": "اضغط هنا أو اسحب صورة",
            "een foto mag": "حجم الصورة لا يتجاوز 5 ميجا",
            "uw foto": "صورتك",
            "verkoop": "يبيع",
            "beschrijving": "وصف",
            "item-title": "عنوان",
            "plaatsen": "أضف",
            "berichten": "الرسائل",
            "annuleren": "الغاء",
            "beantwoorden": "الرد",
            "verwijderen": "حذف",
            "geplaatst door": "الكاتب:",
            'beroep': 'المهنة',
            'hobbies': 'الهوايات',
            'woonplaats': 'المدينة',
            'bericht': 'إرسال',
            'telefoonnummer': 'رقم الهاتف',
            'postcode': 'الرمز البريدي',
            'account verwijderen': 'حذف الحساب',
            'account verwijderen?': 'حذف الحساب؟',
            'account verwijderen dialog': 'قم بادخال كلمة السر. سيتم حذف جميع الاعلانات والرسائل الخاصة بك.',
            "vul interesses": 'ادخل الاهتمامات الخاصة بك, مفصولة بفاصلة.',
            "mijn profiel": "الملف الشخصي",
            'opslaan': 'حفظ',
            "email notificaties": "تلقي البريد الالكتروني للتنبيهات",
            'bericht van': 'رسالة من',
            'advertentie niet gevonden': 'لا يوجد اعلان مطابقة ',
            'email is nog niet geverifieerd': 'لم يتم التحقق من عنوان البريد الإلكتروني',
            'email met instructies is verstuurd': 'تم ارسال رسالة التحقق بنجاح، يرجى التحقق من بريدك الالكتروني للحصول على التعليمات.',
            'verifieer email': 'تحقق من البريد الالكتروني',
            'zoeken2': 'ابحث عن أعضاء آخرين في EgyNL. يمكنك البحث بالاسم والمهنة والهواية ومكان الإقامة. يمكنك إرسال رسائل إلى مستخدمين آخرين، عنوان بريدك الإلكتروني غير مرئي للآخرين.',
            'zoeken3': 'حاول أيضًا البحث باللغة الهولندية.',

          }
      }
    },    
    
  });


export default i18n;