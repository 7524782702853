import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC8w8rBmAzQEfWmFsv1x7JppUwNNxkbta8",
  authDomain: "face-masr.firebaseapp.com",
  databaseURL: "https://face-masr.firebaseio.com",
  projectId: "face-masr",
  storageBucket: "face-masr.appspot.com",
  messagingSenderId: "640262055613",
  appId: "1:640262055613:web:35b31ff9dcabce05d339d4",
  measurementId: "G-S8H5M5KLQK"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Firestore and Storage
const firestore = firebase.firestore();
const storage = firebase.storage();

// Initialize Analytics
const analytics = firebase.analytics();

// Log an event
analytics.logEvent('notification_received');

export { firebase, firestore, storage, analytics };
