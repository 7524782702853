import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next'
//COMPONENTS
import MessagesList from './MessagesList'

class Inbox extends Component {
  render() {
    const { messages, auth, t} = this.props;
    if (!auth.uid) return <Redirect to='/inloggen' />
    if(!Array.isArray(messages) || !messages.length){
      return (
        <div className="page-container">  
          <Typography className="page-title" variant="h5" component="h2">{t('berichten')}
            </Typography>
            <p className='centered'>{t('Geen nieuwe berichten')}</p>
        </div>
      )
    }
    else {
      return (
        <div className="page-container">  
          <Typography className="page-title" variant="h5" component="h2">{t('berichten')}
            </Typography>
            <MessagesList messages= {messages} />
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.firestore.ordered.messages,
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslation(),
  firestoreConnect(props => {
    const userId = props.auth.uid;
    if (!userId) {
      return []; // Return an empty array if user is not logged in
    }    
    return [
      { collection: 'messages', 
      where: ['receiverId', '==', userId], orderBy: ['sentAt', 'desc']}
  ]
})
)(Inbox)