export const createProject = (project) => {
  return async (dispatch, getState, { getFirestore }) => {
    try {
      const firestore = getFirestore();
      const { profile, auth } = getState().firebase;
      const authorId = auth.uid;
      const newProject = {
        ...project,
        authorName: profile.name,
        email: profile.email,
        authorId: authorId,
        approved: false,
        createdAt: new Date()
      };
      await firestore.collection('projects').add(newProject);
      dispatch({ type: 'CREATE_PROJECT_SUCCESS', project: newProject });
    } catch (error) {
      dispatch({ type: 'CREATE_PROJECT_ERROR', error });
      console.error('Error creating project:', error);
    }
  };
};

export const approveProject = (projectId) => {
  return async (dispatch, getState, { getFirestore }) => {
    try {
      const firestore = getFirestore();
      await firestore.collection('projects').doc(projectId).update({
        approved: true
      });
      dispatch({ type: 'PROJECT_APPROVED', projectId });
    } catch (error) {
      console.error('Error approving project:', error);
    }
  };
};

export const deleteProject = (projectId) => {
  return async (dispatch, getState, { getFirestore }) => {
    try {
      const firestore = getFirestore();
      await firestore.collection('projects').doc(projectId).delete();
      dispatch({ type: 'PROJECT_DELETED', projectId });
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };
};

export const sendMessage = (message) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const { profile, auth } = getState().firebase;
    const { uid, email } = auth;
    const messageData = {
      ...message,
      authorName: profile.name,
      authorId: uid,
      authorEmail: email,
      sentAt: new Date()
    };
    firestore
      .collection('messages')
      .add(messageData)
      .then(() => {
        dispatch({ type: 'CREATE_MESSAGE_SUCCESS', payload: messageData });
      })
      .catch((err) => {
        dispatch({ type: 'CREATE_MESSAGE_ERROR', payload: err });
        console.error('Error creating message:', err);
      });
    firestore
    .collection('users')
    .doc(message.receiverId)
    .update({
      newMessages: firestore.FieldValue.increment(1)
    });
  };
};

//resets newMessages to 0
export const resetMsgBadge = (uid) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    firestore
    .collection('users')
    .doc(uid)
    .update({
      newMessages: 0
    });
  }
}

export const deleteMessage = (messageId) => {
  return (dispatch, getState, {getFirestore}) => {
    //call to db
    const firestore = getFirestore();
    firestore.collection('messages').doc(messageId).delete()
    .then( () => {
      dispatch({ type: 'MESSAGE_DELETED', messageId});
    })
  }
}
