import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
//MUI
import AppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//Icons
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import CopyrightIcon from '@material-ui/icons/Copyright';

const socialMediaLinks = {
  facebook: 'https://www.facebook.com/Egynl-106729034702927',
  android: 'https://play.google.com/store/apps/details?id=nl.egynl.twa',
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    overflow: 'hidden',
    maxHeight: '200px'
  },
  link: {
    textDecoration: 'none',
    color: '#fff',
    margin: theme.spacing(0),
    lineHeight: '1.4em',
  },
  li: {
    marginTop: theme.spacing(1),
  },
  icon: {
    color: '#fff',
    margin: theme.spacing(0),
  },
  socialText: {
    fontSize: '0.6em',
    color: '#fff',
    margin: theme.spacing(1),
  },
  gridItem: {
    flex: '1',
    [theme.breakpoints.down('xs')]: {
      width: '100%', // Full width on extra-small screens
      flexBasis: 'auto',
    },
  },
  socialGridItem: {
    textAlign: 'center',
  },
}));

const Footer= () => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    <AppBar position="absolute" color="primary" className={classes.appBar}>
      <Grid container spacing={0}>
        <Grid item xs={6} sm={6} md={5} className={classes.gridItem}>
          <ul style={{ listStyleType: 'none' }}>
          {[
            { name: 'home', path: '/' },
            { name: 'advertenties', path: '/advertenties' },
            { name: 'contact', path: '/contact' },
            { name: 'overons', path: '/overons' },
            { name: 'voorwaarden', path: '/voorwaarden-en-privacy' },
          ].map((route) => (
            <li key={route.name} className={classes.li}>
              <Link to={route.path} className={classes.link}>
                {t(route.name)}
              </Link>
            </li>
          ))}
          </ul>
        </Grid>
        <Grid item xs={6} sm={6} md={5} className={`${classes.gridItem} ${classes.socialGridItem}`}>
          <IconButton
            aria-label="facebook"
            onClick={() => window.open(socialMediaLinks.facebook, '_blank')}
          >
            <FacebookIcon className={classes.icon} fontSize="large" />
            <span className={classes.socialText}>Facebook</span>
          </IconButton>
          <IconButton 
            aria-label="Android" 
            onClick={() => window.open(socialMediaLinks.android, '_blank')}
            style={{ display: 'block', margin: 'auto' }}
            >
            <GetAppSharpIcon className={classes.icon} fontSize="large" />
            <span className={classes.socialText}>Play Store</span>
          </IconButton>
          <IconButton 
            aria-label="Android" 
            style={{ display: 'block', margin: 'auto' }}
            >
            <CopyrightIcon className={classes.icon} fontSize="large" />
            <span className={classes.socialText}>{new Date().getFullYear()} Egynl</span>
          </IconButton>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Footer;