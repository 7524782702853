import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
//COMPONENTS
import UploadProfileImage from './UploadProfileImage'
//ACTIONS
import { updateProfile, reauthenticateUser, deleteUser} from '../../store/actions/authActions'
import {
  Grid,
  makeStyles,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Snackbar,
  SnackbarContent,
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

  TextField: {
    margin: '8px', width: '300px'
  },
  grayedOut: {
    marginTop: '20px', color: 'grey'
  },
}));

const Profile = ({ auth, updateError, profile, updateProfile, reauthenticateUser, deleteUser }) => {
  const [formData, setFormData] = useState({
    userId: '',
    phoneNr: '',
    zipCode: '',
    city: '',
    work: '',
    hobby1: '',
    picture: '',
    emailNotification: false,
  });

  const [stateRetrieved, setStateRetrieved] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [profileChanged, setProfileChanged] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [reauthPassword, setReauthPassword] = useState('');

  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (!stateRetrieved && !profile.isEmpty) {
      const {
        phoneNr = '',
        zipCode = '',
        city = '',
        work = '',
        hobby1 = '',
        picture = '',
        emailNotification = false,
      } = profile;
      
      setFormData({
        userId: auth.uid,
        phoneNr,
        zipCode,
        city,
        work,
        hobby1,
        picture,
        emailNotification,
      });
      setStateRetrieved(true);
    }
  }, [stateRetrieved, profile, auth.uid]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setProfileChanged(true);
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setFormData({ ...formData, [id]: checked });
    setProfileChanged(true);
  };

  const handleFileChange = (fileUrl) => {
    const msg = updateError ? updateError : 'Foto gewijzigd'
    const updatedFormData = { ...formData, picture: fileUrl };
    setSnackbarMessage(msg);
    updateProfile(updatedFormData);
    setFormData(updatedFormData); 
    setSnackbarOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();    
    updateProfile({ ...formData });
    const msg = updateError ? updateError : 'Profiel gewijzigd'
    setSnackbarMessage(msg);
    setSnackbarOpen(true);
    setProfileChanged(false);
  };

  const handleReauthPasswordChange = (e) => {
    setReauthPassword(e.target.value);
  };

  const deleteAccount = () => {
    // Dispatch the reauthenticateUser action
    reauthenticateUser(auth.email, reauthPassword)
      .then(() => {
        // Reauthentication successful, proceed with account deletion
        deleteUser();
        const msg = updateError ? updateError : 'Bezig met verwijderen..';
        setDialogOpen(false);
        setSnackbarMessage(msg);
        setSnackbarOpen(true);
      })
      .catch(error => {
        // Handle reauthentication failure
        console.error('Reauthentication failed:', error);
        const msg = 'Verificatie mislukt. Vul de correcte wachtwoord in.';
        setSnackbarMessage(msg);
        setSnackbarOpen(true);
      });
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  if (!auth.uid) return <Redirect to='/inloggen' />
  return (
    <Grid container className="grid-container">
      <Grid item sm />
      <Grid item sm={10}  style={{maxWidth: '800px', margin: '0 auto'}}>
        <Typography className="page-title" variant="h5" component={"h2"}>
          {t('mijn profiel')}
        </Typography>
        <div className="profile">
          <UploadProfileImage 
          className="profile-pic" 
          picture={profile.picture}
          userId={auth.uid}
          handleFileChange={handleFileChange}
          />
          <p className={classes.grayedOut}> {t('naam kort')}: {profile.name}</p>
          <p className={classes.grayedOut}> {t('email')}: {auth.email}</p>     
        </div>
        <form onSubmit={handleSubmit}>
          <TextField
          id="phoneNr"
          type="phone"
          label={profile.phoneNr ? t("telefoonnummer") + " : "  + profile.phoneNr : t("telefoonnummer")}
          onChange={handleChange}
          autoComplete='tel'
          className={classes.TextField}
          />
          <TextField
          id="zipCode"
          type="text"
          label={profile.zipCode ? t("postcode") + " : "  + profile.zipCode : t("postcode")}
          onChange={handleChange}
          className={classes.TextField}
          />
          <TextField
          id="city"
          type="text"
          label={profile.city ? t("woonplaats") + " : "  + profile.city : t("Woonplaats")}
          onChange={handleChange}
          className={classes.TextField}
          />
          <TextField
          id="work"
          type="text"
          label={profile.work ? t("beroep") + " : "  + profile.work : t("beroep")}
          onChange={handleChange}
          className={classes.TextField}
          />
          <TextField
          id="hobby1"
          type="text"
          label={profile.hobby1 ? t("hobbies") + " : "  + profile.hobby1 : t("hobbies")}
          onChange={handleChange}
          fullWidth
          helperText= {t("vul interesses")}
          style={{margin: '8px'}}
          />
        <FormControlLabel
          control={
            <Checkbox
              checked={ formData.emailNotification }
              onChange={handleCheckboxChange}
              id="emailNotification"
              color="primary"
            />
          }
          label= {t("email notificaties")}
          style={{margin: '8px 0'}}
        />   
        <br/>       
          <Button type="submit" 
            style={{marginTop: '10px'}}
            variant="contained" 
            color="primary" 
            disabled={!profileChanged}
            >
              {t("opslaan")}
          </Button>
        </form>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          
          onClose={closeSnackbar}
          TransitionComponent={Slide}
          >
          <SnackbarContent
          style={{background: "grey", textAlign: "center"}}
          message={snackbarMessage}
          />
        </Snackbar>
        <div>
          <Button variant="outlined" 
            color="secondary" 
            style={{marginTop: '30px'}}
            onClick={openDialog}
          >
            {t("account verwijderen")}
          </Button>
          <Dialog
            open={dialogOpen}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t("account verwijderen?")}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("account verwijderen dialog")}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="reauthPassword"
                label={t("wachtwoord")}
                type="password"
                fullWidth
                value={reauthPassword}
                onChange={handleReauthPasswordChange}
            />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary"
                variant="contained"
              >
                {t("annuleren")}
              </Button>
              <Button onClick={deleteAccount} color="secondary" 
                variant="contained" autoFocus>
                {t("account verwijderen")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
      <Grid item sm />
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return{
    auth: state.firebase.auth,
    updateError: state.auth.ProfileUpdateError,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
    updateProfile: (profile) => dispatch(updateProfile(profile)),
    deleteUser: () => dispatch(deleteUser()),
    reauthenticateUser: (email, password) => dispatch(reauthenticateUser(email, password)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
