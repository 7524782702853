import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {Link} from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { sendMessage } from '../../store/actions/projectActions';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const Contact = ({ t, auth, sendMessage }) => {
  const [state, setState] = useState({
    category: '',
    message: '',
    messageSubject: '',
    receiverEmail: 'ramy.shehata@gmail.com',
    receiverId: 'iIMGX74FiUV2fI8vvdlMOBC8zh52',
    messageHelper: 'Type uw bericht',
  });

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
      messageSubject: `${prevState.category}: ${prevState.title}`,
    }));
  };

  const handleSelectChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      category: e.target.value,
      messageSubject: prevState.category + ': ' + prevState.title,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage(state);
    setState({
      ...state,
      message: '',
      messageHelper: 'Uw bericht is verstuurd',
    });
  };

  const { category, message, messageHelper } = state;

  const form = auth.uid ?
    <form onSubmit={handleSubmit} className="contact-form">
      <FormControl required className="form-control">
        <InputLabel id="categoryLabel" className="input-label">
          {t('categorie')}
        </InputLabel>
        <Select
          labelId="categoryLabel"
          id="category"
          value={category}
          onChange={handleSelectChange}
          autoWidth
          className="select-input"
        >
          <MenuItem value="Suggesties">{t('suggesties')}</MenuItem>
          <MenuItem value="klachten">{t('klachten')}</MenuItem>
          <MenuItem value="Overige">{t('overige')}</MenuItem>
        </Select>
        <FormHelperText className="helper-text">
          {t('kies een categorie')}
        </FormHelperText>
      </FormControl>
      <TextField
        required
        type="text"
        id="title"
        label={t('onderwerp')}
        className="text-field"
        onChange={handleChange}
      />
      <TextareaAutosize
        required
        className="text-area"
        id="message"
        onChange={handleChange}
        aria-label="minimum height"
        minRows={8}
        placeholder={t(messageHelper)}
        value={message}
      />
      <div>
        <Button type="submit" variant="contained" color="secondary"
          disabled={messageHelper === 'Uw bericht is verstuurd'}
          className="button-container"
        >
          {t('versturen')}
        </Button>
        <FormHelperText>{messageHelper}</FormHelperText>
      </div>
    </form>

    :
    <div className="centered" style={{ padding: '10px 0px'}}>
      <p>{t('contact-2')}</p>
      <Button color="secondary"  style={{margin: '0px 10px'}} variant="outlined" component={Link} to="/aanmelden">{t('aanmelden')}</Button>
      <Button color="secondary"  variant="outlined" component={Link} to="/inloggen">{t('inloggen')}</Button>
    </div>;
  

  return (
    <div className="page-container">
      {scrollToTop()}
      <Typography className="page-title" variant="h5" component="h2">
        {t('contact')}
      </Typography>
      <p>
        {t('contact-1')}
        <a href='mailto:contactegynl@gmail.com'>e-mail</a>. 
        {t('contact-3')}
      </p>
      {form}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
});

const mapDispatchToProps = (dispatch) => ({
  sendMessage: (message) => dispatch(sendMessage(message)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Contact);
