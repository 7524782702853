import React, { useState } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
//COMPONENTS
import ContactUser from '../ads/ContactUser'
//ACTIONS
import { approveProject } from '../../store/actions/projectActions'
import { deleteProject } from '../../store/actions/projectActions'
//MUI
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MessageIcon from '@material-ui/icons/Message';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginTop: '20px',
    overflow: "visible"    
  },
  title: {
    paddingTop: 10,
    marginBottom: '20px'
  },
  text: {
    marginBottom: '20px'
  },
  pos: {
    marginBottom: 12,
  },
  media: {
    marginTop: 20,
    height: 600,
    width: '100%',
    objectFit: 'cover',
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
  },
  button: {
    margin: '10px'

  }
});

const ProjectDetails = (props) => {
  const [isContactShown, setContactShown] = useState(false);
  const { project, auth } = props;
  const classes = useStyles();
  const handleContact = () => {
    isContactShown ? setContactShown(false) : setContactShown(true)
  } 
  if (!auth.uid) return <Redirect to='/inloggen' /> 

  const handleApprove = () => {
    props.approveProject(props.id);
  }

  const handleDelete = () => {
    props.deleteProject(props.id);
    // // this.props.history.push('/success')
  }

  if (project) {
    const img = project.file ? project.file : "../img/egynl-banner.webp";
    return (
      <div className="page-container">
        <Card className={classes.root}>
          <div style={{margin: 'auto', textAlign:'center'}}>
            <img style={{  maxWidth: '100%' , height: 'auto' }}src={img} alt="foto"/>
          </div>
          <CardContent>
            <Typography className={classes.title} variant="h5" component="h2"> {project.title} </Typography>
            <Typography className={classes.text} variant="body2" component="p">{project.content}</Typography>
            <Typography color="textSecondary" gutterBottom>Geplaatst door {project.authorName}
            </Typography>
            <Typography  color="textSecondary" gutterBottom>{moment(project.createdAt.toDate()).format('DD/MM/YYYY')}
            </Typography>
          </CardContent>
          {isContactShown && <ContactUser props={props.project}/>} 
          <Button className={classes.button} color="secondary" variant="contained" 
          startIcon={isContactShown ? <CloseIcon/> : <MessageIcon/>}
          onClick={handleContact}>
            {isContactShown ? "Annuleren" : "Bericht"}
          </Button>     
          <Button variant="contained" color="primary" onClick={handleApprove}>
            Goedkeuren
          </Button>
          <Button variant="contained" color="primary" onClick={handleDelete}>
            Verwijderen
          </Button>           
        </Card>
      </div>
    )
  } else {
    return (
      <div className="container center">
        <p>Loading project..</p>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const projects = state.firestore.data.projects;
  const project = projects ? projects[id] : null
  return {
    project: project,
    auth: state.firebase.auth,
    id: id
  }
}

const mapDispatchToProps = (dispatch)=> {
  return {
    approveProject: (projectId) => dispatch(approveProject(projectId)),
    deleteProject: (projectId) => dispatch(deleteProject(projectId))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{
    collection: 'projects'
  }])
)(ProjectDetails)
