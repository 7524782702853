import { firebase } from './../../config/fbConfig';

export const signIn = (credentials) => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();
    firebase.auth().signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    ).then(() => {
      dispatch({ type: 'LOGIN_SUCCESS' });
    }).catch((err) => {
      dispatch({ type: 'LOGIN_ERROR', err });
    });

  }
}

export const signOut = () => {
  return async (dispatch, getState, { getFirebase }) => {
    try {
      const firebase = getFirebase();
      await firebase.auth().signOut();
      dispatch({ type: 'SIGNOUT_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'SIGNOUT_ERROR', err });
    }
  };
};

export const signUp = (newUser) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const firebase = getFirebase();
      const firestore = getFirestore();
      firebase.auth().languageCode = 'nl';
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(
        newUser.email,
        newUser.password
      );
      const { user } = userCredential;
      await firestore.collection('users').doc(user.uid).set({
        name: newUser.name,
        email: newUser.email,
        emailNotification: true,
        newMessages: 0
      });
      await user.sendEmailVerification();
      dispatch({ type: 'SIGNUP_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'SIGNUP_ERROR', err });
    }
  };
};

//resend verification email
export const verifyEmail = () => {
  return async (dispatch, getState, { getFirebase }) => {
    try {
      const firebase = getFirebase();
      await firebase.auth().currentUser.sendEmailVerification();
      dispatch({ type: 'EMAIL_VERIFICATION_SENT' });
    } catch (err) {
      dispatch({ type: 'EMAIL_VERIFICATION_ERROR', err });
    }
  };
}

export const updateProfile = (profile) => {
  return async (dispatch, getState, { getFirestore }) => {
    try {
      const firestore = getFirestore();
      await firestore.collection('users').doc(profile.userId).update({
        phoneNr: profile.phoneNr,
        zipCode: profile.zipCode,
        city: profile.city,
        work: profile.work,
        hobby1: profile.hobby1,
        picture: profile.picture,
        emailNotification: profile.emailNotification,
      });
      dispatch({ type: 'PROFILE_UPDATED' });
    } catch (err) {
      dispatch({ type: 'PROFILE_UPDATE_ERROR', err });
    }
  };
};

export const resetPassword = (email) => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();
    firebase.auth().languageCode = 'nl';
    firebase.auth().sendPasswordResetEmail(email).then( ()=> {
      dispatch({ type: 'PASSWORDRESET_SUCCESS'});
    }).catch( (err)=> {
      console.log(err)
      dispatch({ type: 'PASSWORDRESET_ERROR', err});
    });
  }
}

export const reauthenticateUser = (email, password) => {
  return (dispatch) => {
    const credentials = firebase.auth.EmailAuthProvider.credential(email, password);

    // Reauthenticate the user
    return firebase.auth().currentUser.reauthenticateWithCredential(credentials);
  };
};

//work around
export const deleteUser = () => {
    try{ 
      const user = firebase.auth().currentUser;
      if (user) user.delete();
      return { type: 'DELETE_USER_SUCCESS' };
    }
    catch(err){
      return { type: 'DELETE_USER_ERROR', err };
    }
}

// export const deleteUser2 = () => {
//   return async (dispatch, getState, { getFirebase }) => {
//     console.log('Attempting to delete user');
//     try {
//       const firebase = getFirebase();
//       const user = firebase.auth().currentUser;
//       if (user) {
//         await user.delete();
//         dispatch({ type: 'DELETE_USER_SUCCESS' });
//       } else {
//         // no user is authenticated
//         dispatch({ type: 'DELETE_USER_ERROR', err: 'No user authenticated' });
//       }
//     } catch (err) {
//       console.error('Error deleting user:', err);
//       dispatch({ type: 'DELETE_USER_ERROR', err });
//     }
//   };
// };
