import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import algoliasearch from 'algoliasearch';
import { InstantSearch, PoweredBy, SearchBox, Hits } from 'react-instantsearch';
import SearchHit from './SearchHit'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const algoliaClient = algoliasearch('IWKYFMC4L8', '951552c1272eefadb4d1b59859443183');

const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      });
    }
    return algoliaClient.search(requests);
  },
  future: {
    preserveSharedStateOnUnmount: false,
  },
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    marginTop: 10,
  },
  searchBox: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  searchButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  searchIcon: {
    color: '#fff',
    height: '100%'
  },
  poweredBy: {
    width: '150px',
    marginBottom: theme.spacing(1),
    display: 'none',
  }

}));

function Search(props){

  const {auth} = props;
  const classes = useStyles();
  const query = window.history.state && window.history.state.state 
  && window.history.state.state.search ? window.history.state.state.search : '';
  const { t } = useTranslation();

  if (!auth.uid) return <Redirect to='/inloggen' />

  return (
    <div className='page-container'>
      <Typography 
        className="page-title" 
        variant="h5" 
        component={"h2"}
        style={{marginBottom: 20}}
      >
        {t('zoeken')}
      </Typography>
      
      <InstantSearch
        searchClient={searchClient}
        indexName="users_egynl"
        initialUiState={{
          users_egynl: {
            query: query,
          },
        }}
      >
      <div className='centered'>
        <SearchBox
          placeholder= {t('zoeken')}
          className= {{
            root: classes.root,
            input: classes.searchBox,
            submit: classes.searchButton,
            submitIcon: classes.searchIcon
          }}
        />
      </div>
      <Hits hitComponent={SearchHit} />
      <p style={{margin: '40px 0px'}}>{t('zoeken2')} {t('zoeken3')}</p>
      <PoweredBy
        className={classes.poweredBy} />
      </InstantSearch>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    users: state.firestore.ordered.users,
  }
}

export default connect(mapStateToProps)(Search)
