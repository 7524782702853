import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import logo from '../../img/logo-small.png'
//ACTIONS
import { signOut } from '../../store/actions/authActions'
import { resetMsgBadge } from '../../store/actions/projectActions'
//MUI
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';

import {
  MenuOutlined as MenuIcon,
  Search as SearchIcon,
  AccountCircleOutlined,
  Language as LanguageIcon,
  MailOutline as MailIcon,
  CallOutlined as CallIcon,
  VpnKeyOutlined as VpnKeyIcon,
  AnnouncementOutlined as AnnouncementIcon,
  InfoOutlined as InfoIcon,
  CreateOutlined as CreateIcon,
  LockOpen as LockOpenIcon,
  LocalLibraryOutlined as LocalLibraryIcon,
  MenuBookOutlined as MenuBookIcon,
  HomeOutlined as HomeIcon,
  LibraryBooksOutlined as LibraryBooksIcon,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  logo: {
    maxWidth: 30,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  iconButton: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 10,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',//theme.spacing(1),
      width: 'auto',
      overflow: 'visible', //todo
    },
  },
  searchIcon: {
    color: '#fff',
    height: '100%'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

// MenuBar routes
const ROUTES = {
  HOME: '/',
  LOGIN: '/inloggen',
  REGISTER: '/aanmelden',
  NEWS: '/nieuws',
  INBOX: '/inbox',
  PROFILE: '/profiel',
  ADS: '/advertenties',
  PLACE_AD: '/advertentieplaatsen',
  MY_ADS: '/mijnadvertenties',
  CONTACT: '/contact',
  ABOUT: '/overons',
  TERMS: '/voorwaarden-en-privacy',
};

const Navbar = (props) => {
  const { auth, profile } = props;
  const [anchorEl, setAnchorEl] = useState(null); //account menu
  const [anchorEl2, setAnchorEl2] = useState(null); //language menu
  const [searchValue, setSearchValue] = useState("");
  const { t, i18n } = useTranslation();
  const open = Boolean(anchorEl);

  // Define menu items
  const menuItems = {
    signedIn: [
      { text: t('home'), route: ROUTES.HOME, icon: <HomeIcon /> },
      { text: t('advertentie plaatsen'), route: ROUTES.PLACE_AD, icon: <CreateIcon/> },
      { text: t('advertenties'), route: ROUTES.ADS, icon: <MenuBookIcon/> },
      { text: t('nieuws'), route: ROUTES.NEWS, icon: <LocalLibraryIcon/> },
      { text: t('inbox'), route: ROUTES.INBOX, icon: <MailIcon/> },
      { text: t('profiel'), route: ROUTES.PROFILE, icon: <AccountCircleOutlined /> },
      { text: t('mijn advertenties'), route: ROUTES.MY_ADS, icon: <LibraryBooksIcon/> },
      { text: t('contact'), route: ROUTES.CONTACT, icon: <CallIcon /> },
      { text: t('overons'), route: ROUTES.ABOUT, icon: <InfoIcon/> },
      { text: t('voorwaarden'), route: ROUTES.TERMS, icon: <AnnouncementIcon/> },
    ],
    signedOut: [
      { text: t('home'), route: ROUTES.HOME, icon: <HomeIcon /> },
      { text: t('inloggen'), route: ROUTES.LOGIN, icon: <VpnKeyIcon/> },
      { text: t('aanmelden'), route: ROUTES.REGISTER, icon: <LockOpenIcon/> },
      { text: t('contact'), route: ROUTES.CONTACT, icon: <CallIcon /> },
      { text: t('Over ons'), route: ROUTES.ABOUT, icon: <InfoIcon/> },
      { text: t('voorwaarden'), route: ROUTES.TERMS, icon: <AnnouncementIcon/> },
    ],
  }

  const mapMenuItems = (items) => {
    return (
      <List>
        {items.map(({ text, route, icon }, index) => (
          <ListItem button key={text} component={Link} to={route}
            onClick={() => handleMenuItemClick(text)}
          >
            <ListItemIcon>
              {route === ROUTES.INBOX ? (
                <Badge 
                  overlap="rectangular" //overlap="rectangle" was deprecated warning fix
                  badgeContent={ profile.newMessages}
                  color="secondary">
                  {icon}
                </Badge>
              ) : (
                icon
              )}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    );
  };

  // call resetMsgBadge when inbox is clicked to reset newMessages
  const handleMenuItemClick = (text) => {
    if (text === t('inbox')) {
      props.resetMsgBadge(auth.uid);
    }
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setAnchorEl2(null);
  };

  //search submit
  const handleSubmit = e => {
    e.preventDefault();
    if (searchValue) {
      props.history.push({
        pathname: '/zoeken',
        state: {search: searchValue}
      })
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  const userLinks = auth.uid ? 
    <div>
      <MenuItem component={Link} to={ROUTES.PROFILE} onClick={handleClose}>{profile.name}</MenuItem>
      <MenuItem onClick={()=> {props.signOut(); handleClose();}}>{t('uitloggen')}</MenuItem>
    </div> 
  :
    <div>
      <MenuItem component={Link} to="/inloggen" onClick={handleClose}>{t('inloggen')}</MenuItem>
      <MenuItem component={Link} to="/aanmelden" onClick={handleClose}>{t('aanmelden')}</MenuItem>
    </div>;

  const navLinks = auth.uid ? mapMenuItems(menuItems.signedIn) : mapMenuItems(menuItems.signedOut);

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleAccountMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenu = event => {
    setAnchorEl2(event.currentTarget);
  };

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      {navLinks}
    </div>
  );
 
  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <IconButton edge="start" onClick={toggleDrawer('left', true)} className={classes.menuButton} 
          color="inherit" aria-label="menu">
        <MenuIcon />
        </IconButton>
        <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
          {sideList('left')}
        </Drawer>
        <Link to='/'>
          <img src={logo} alt="logo" className={classes.logo}/>
        </Link>
        <Button color="inherit" component={Link} to="/">
          <Typography variant="h6" className={classes.title} >
            EGYNL
          </Typography>
        </Button>
        <form className={classes.search} onSubmit={handleSubmit}>
          <InputBase
            name='search'
            placeholder={t('zoeken')}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={e => setSearchValue(e.target.value)}
            inputProps={{ 'aria-label': 'search' }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Zoeken"
                  onClick={handleSubmit}
                  onMouseDown={handleSubmit}
                >
                <SearchIcon className={classes.searchIcon}/>
                </IconButton>
              </InputAdornment>
            }
          />
        </form>
        <IconButton
          className={classes.iconButton}
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleLanguageMenu}
          color="inherit"
        >
        <LanguageIcon/>
        </IconButton>
        <Menu
          id="lang-menu"
          anchorEl={anchorEl2}
          keepMounted
          open={Boolean(anchorEl2)}
          onClose={handleClose}
          >
          <MenuItem onClick={() => handleLanguageChange('nl')}>Nederlands</MenuItem>
          <MenuItem onClick={() => handleLanguageChange('ar')}>عربى</MenuItem>
        </Menu>
        <IconButton
          className={classes.iconButton}
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleAccountMenu}
          color="inherit"
        >
        <AccountCircleOutlined/>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          {userLinks}
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = (state) => {
  return{
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetMsgBadge: (uid) => dispatch(resetMsgBadge(uid)),
    signOut: () => dispatch(signOut())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));

