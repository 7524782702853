import React from 'react'
import { Link } from 'react-router-dom'
//COMPONENTS
import SelectCategory from '../pages/SelectCategory' //category banner
import AdOverview from './AdOverview'

/**
 * Maps AdOverview and creates a link to the ad
 * Called by ApprovedAds, Dashboard, News, Jobs, Market and UserProjects
 * @returns 
 */
const AdsList = ({projects, path}) => {  
  return (    
    <div>
      <section id="top"/>
      <SelectCategory/>
      {projects && projects.map(project => {     
        return (          
          <Link to={path+"/"+project.id} key={project.id}
          style={{ textDecoration: 'none' }}
          >
            <AdOverview project={project} />
          </Link>
        )
      })}  
    </div>
  )
}

export default AdsList
