const initState = {
  authError: null,
  passwordReset: null,
  profileUpdateError: null
}

const authReducer = (state = initState, action) => {
  switch(action.type){
    case 'LOGIN_ERROR':
       return {
        ...state,
        authError: 'Er is een fout opgetreden, controleer uw gebruikersnaam en wachtwoord'
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        authError: null
      }
    case 'SIGNOUT_SUCCESS':
      return {
        ...state,
        authError: null
      }
    case 'SIGNUP_SUCCESS':
      return {
        ...state,
        authError: null
      }
    case 'SIGNUP_ERROR':
      return {
        ...state,
        authError: action.err.message
      }
    case 'PROFILE_UPDATED':
      return {
        ...state,
        profileUpdateError: null,
        authError: null
      }
    case 'PROFILE_UPDATE_ERROR':
      return {
        ...state,
        profileUpdateError: action.err.message,
      };
      case 'DELETE_USER_SUCCESS':
        return {
          ...state,
          profileUpdateError: null,
          authError: "Account verwijderd"
        }
      case 'DELETE_USER_ERROR':
        return {
          ...state,
          profileUpdateError: action.err.message,
        };
    case 'PASSWORDRESET_SUCCESS':
      return {
        ...state,
        passwordReset: 'Een email met instructies is verstuurd',
        authError: null
      };
    case 'PASSWORDRESET_ERROR':
      return {
        ...state,
        passwordReset: 'Controleer uw email',
        authError: action.err.message 
      };
    default:
      return state
  }
};

export default authReducer;
