import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Redirect, Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
//COMPONENTS
import ResetPassword from './ResetPassword'
//ACTIONS
import { signIn } from '../../store/actions/authActions'
import { resetPassword } from '../../store/actions/authActions'
// MUI
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

const styles = {
  form: {
    textAlign: 'center'
  },
  pageTitle: {
    margin: '20px auto 40px',
    textAlign: 'center'
  },
  textField: {
    margin: '10px auto',
    maxWidth: '600px',
    display: 'block'
  },
  button: {
    margin: '20px auto',
    position: 'relative'
  },
  customError: {
    color: 'red',
    fontSize: '0.8em',
    marginTop: '10px',
    display: 'flex',
    width: '100%'
  },
  passReset: {
    marginTop: '10px'
  },
  small: {
    marginTop: '10px'
  },
  alert: {
    maxWidth: '600px',
    margin: '0px auto 20px'
  }
}

class SignIn extends Component {
  state = {
    email: '',
    password: '',
    passwordReset: false,
    resetEmail: ''
  }

  resetPassword = ()=> {
    this.setState({
      ...this.state,
      passwordReset: true
    })
  }
  handleReset = (e)=> {
    e.preventDefault();
    this.props.resetPassword(this.state.resetEmail)
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state)
  }
  render() {
    const { authError, auth, classes, passwordReset } = this.props;
    const { t } = this.props;
    if (auth.uid) return <Redirect to='/' /> 

    return (
      <Grid container className="grid-container">
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography className={classes.pageTitle} variant="h5" component="h2">
          {t('inloggen')}
          </Typography>
          <form  className={classes.form} onSubmit={this.handleSubmit}>
            <TextField
            required
            id="email"
            name="email"
            type="email"
            label={t("email")}
            autoComplete='email'
            className={classes.textField}
            value={this.state.email}
            onChange={this.handleChange}
            fullWidth
            />
            <TextField
            required
            id="password"
            name="password"
            type="password"
            label={t('wachtwoord')}
            className={classes.textField}
            value={this.state.password}
            onChange={this.handleChange}
            fullWidth
            />
            <Button type="submit" className={classes.button} variant="contained" color="primary">{t('inloggen')}</Button>
            <div className={classes.customError}>
              { authError ? <Alert className={classes.alert} severity="error">{authError}</Alert> : null }
            </div>
          </form>
          <div className='centered'>
            <small>
              {t('nog geen account')}<Link to="/aanmelden">{t('hier2')}</Link>
            </small>
            <small>
              <p>{t('wachtwoord vergeten')}
                <Button
                style={{textTransform: 'none'}} 
                onClick={this.resetPassword}><small>{t('reset uw wachtwoord')}</small>
                </Button>
              </p>
              {this.state.passwordReset ? 
                <ResetPassword handleReset={this.handleReset} 
                handleChange={this.handleChange}/> 
                : null}
              <div className={classes.passReset}>{passwordReset}</div>
            </small>
          </div>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return{
    authError: state.auth.authError,
    passwordReset: state.auth.passwordReset,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    resetPassword: (email) => dispatch(resetPassword(email))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(withStyles(styles)(SignIn));
