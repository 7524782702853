import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import { withTranslation } from 'react-i18next';
//COMPONENTS
import Terms from '../pages/Terms'
//ACTIONS
import { signUp } from '../../store/actions/authActions'
// MUI Stuff
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';

const styles = {
  form: {
    textAlign: 'center'
  },
  pageTitle: {
    margin: '20px auto 40px',
    textAlign: 'center'
  },
  textField: {
    margin: '4px auto',
    maxWidth: '600px',
    display: 'block',
  },
  button: {
    marginTop: '20px',
    position: 'relative'
  },
  label: {
    fontSize: '0.8em',
    marginTop: '10px'
  },
  customError: {
    color: 'red',
    fontSize: '0.8em',
    marginTop: '10px'
  },
  modal: {
    position:'absolute',
    top:'10%',
    left:'10%',
    width: '80%',
    backgroundColor: 'white',
    overflow:'scroll',
    height:'100%',
    display:'block',
    border: '1px solid #000',
    boxShadow: '2px'

  },
  alert: {
    maxWidth: '600px',
    margin: '20px auto 0px'
  }
}

class SignUp extends Component {
  state = {
    email: '',
    password: '',
    name: '',
    checked: false,
    open: false,
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleCheck = () => {
    this.state.checked ? this.setState({...this.state, checked: false }) :
    this.setState({...this.state, checked: true})
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signUp(this.state);
  }

  handleOpen = () => {
    this.setState({...this.state, open: true})
  };

  handleClose = () => {
    this.setState({...this.state, open: false})
  };

  render() {
    const { auth, authError, classes } = this.props;
    const { t } = this.props;
    if (auth.uid) return <Redirect to='/' /> 
    return (
      <Grid container className="grid-container">
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography className={classes.pageTitle} component="h2" variant="h5">
          {t('aanmelden')}
          </Typography>

          <form className={classes.form}
          onSubmit={this.handleSubmit}>
            <TextField required
            className={classes.textField}
            id="email"
            name="email"
            type="email"
            label={t('email')}
            autoComplete='email'
            onChange={this.handleChange}
            fullWidth
            />
            <TextField required
            className={classes.textField}
            id="password"
            name="password"
            type="password"
            label={t('wachtwoord')}
            onChange={this.handleChange}
            fullWidth
            />
            <TextField required
            className={classes.textField}
            id="name"
            name="name"
            type="text"
            label={t('naam')}
            autoComplete='name'
            onChange={this.handleChange}              
            fullWidth
            />
            <FormControlLabel className={classes.label}
              control ={
                <Switch required
                  name='agree'
                  checked={this.state.checked}
                  onChange={this.handleCheck}
                  inputProps={{ 'aria-label': 'Algemene voorwaarden' }}
                />
              }
              label={<Typography style={styles.label}>
                <Button style={{textTransform: 'none', marginLeft: '0px', fontSize: '1em'}} 
                  onClick={this.handleOpen}>
                  {t('met de gebruiksvoorwaarden')}
                </Button></Typography>}
              />            
              <Modal
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="userAgreement"
                aria-describedby="userAgreement"
                >
                <div className={classes.modal}><Terms/></div>
              </Modal>
            <br />
            <Button type="submit" className={classes.button} variant="contained" color="primary"
              // disabled={!(this.state.content && this.state.title && this.state.category !== '')}
            >
            {t('registreren')}
            </Button>
            <div className={classes.customError}>
              { authError ? <Alert className={classes.alert} severity="error"> {authError}</Alert> : null }
            </div>
            <br />
            <small>
            {t('heeft u al een account')} <Link to="/inloggen">{t('hier')}</Link>
            </small>
          </form>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  }
}

const mapDispatchToProps = (dispatch)=> {
  return {
    signUp: (creds) => dispatch(signUp(creds))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps), 
  withTranslation()
  )(withStyles(styles)(SignUp));