import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import firebase from 'firebase/compat/app'

class UploadProfileImage extends Component {

    state = {
      image: '../img/maskable_icon.png'
    }

    onFilesDrop = (files) => {
      var file = files[0];
      const { userId, handleFileChange } = this.props;

      const storageRef = firebase.storage().ref(`profiles/${userId}/${userId}`);
      var uploadTask = storageRef.put(file); //upload file

      uploadTask.on('state_changed',(snapshot) => {},
      (err) => {
        console.log(err);
        },
      () => {
        // Handle successful uploads on complete
        // call parent
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          handleFileChange(downloadURL);
          this.setState({
            image : downloadURL || '../img/maskable_icon.png'
          })
        });
      }
      );
    }
  
    render() {
      const img = this.props.picture || this.state.image
      const imageFormats = {
        'image/jpeg': [],
        'image/png': [],
        'image/webp': []
      };
      return(
        <Dropzone 
          onDrop={this.onFilesDrop}
          accept={imageFormats}
          minSize={0}
          maxSize={5242880}
        >
          {({getRootProps, getInputProps}) => (
            <div className="profile-pic-container" style={{backgroundImage: `url(${img})`}}{...getRootProps()}>
              <input {...getInputProps()} />
              <p>Foto wijzigen</p>
            </div> 
          )}
        </Dropzone>
      )
    }
  }
  
  export default UploadProfileImage