import React, { Component } from 'react'
import AdsList from '../ads/AdsList'
import Notifications from './Notifications'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'

class Dashboard extends Component {
  render() {
    const { projects, auth, notifications, profile } = this.props;
    if (!auth.uid) return <Redirect to='/inloggen' />
    if (profile.email && !profile.admin) return <Redirect to='/' /> //profile retrieved but not admin TODO
    const path = this.props.match.path

    return (
      <Grid container spacing={10}>
        <Grid item sm={8} xs={12}>
          <AdsList projects= {projects} path= {path} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Notifications notifications= {notifications} />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.firestore.ordered.projects,
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications,
    profile: state.firebase.profile
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'projects', orderBy: ['createdAt', 'desc']},
    { collection: 'notifications', limit: 18, orderBy: ['time', 'desc']}
  ])
)(Dashboard)
