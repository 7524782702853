import React from 'react'
import MessageSummary from './MessageSummary'

//called by Inbox
const MessageList = ({messages}) => {

  return (
    <div>      
      { messages && messages.map(message => {        
        return (
          <div key={message.id}>
            <MessageSummary message={message} />
          </div>        )
      })} 
    </div>
  )
}

export default MessageList