import React from 'react'
import { useTranslation } from 'react-i18next';
//MUI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    textAlign: "center",
  },
  pageContainer: {
    margin: 20,
    backgroundImage: 'url("/img/egynl-banner.png")',
    backgroundRepeat: "no-repeat",
    opacity: 0.3
  }
}));

const Terms = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };

  return (
    <div className="page-container" style={{}}>
      {scrollToTop()}
      <Typography className="page-title" variant="h5" component="h2">
        Algemene voorwaarden</Typography>
      <p>
      Door een account aan te maken op onze Website gaat u akkoord met deze Gebruiksvoorwaarden. EgyNL kan deze Gebruiksvoorwaarden van tijd tot tijd wijzigen.</p>
      <p>
      Door een account aan te maken kunt u advertenties bekijken. Na het verifieren van uw emailadres kunt u advertenties plaatsen en berichten sturen.
      </p>
      <p>
      Op mijn profiel kunt emailnotificaties aan en uitzetten en uw account verwijderen. Het verwijderen van uw account verwijdert ook alle advertenties en berichten.
      </p>
      <p>
      Door een Gebruiker kunnen op de Website producten en diensten worden aangeboden of gezocht, 
      Een Advertentie omvat onder meer de titel, categorie en de omschrijving van de aangeboden producten of diensten.
      </p>
      <p>Bescherming van uw privacy<br/> EGYNL gaat zorgvuldig met uw gegevens om. EGYNL website gebruikt geen cookies. We gebruiken Google analytics enkel om onze diensten te verbeteren.
      We bewaren uw data niet langer dan het nodig is. U kunt alle tijden uw account verwijderen, op de mijn profiel pagina.
      Daarmee verwijdert u ook alle advertenties en berichten.</p>
      <p>Minderjarigen <br/>
      De diensten van EgyNL zijn slechts toegankelijk voor minderjarige Gebruikers indien zij daarvoor toestemming 
      hebben gekregen van hun wettelijke vertegenwoordiger of het in het maatschappelijk verkeer 
      gebruikelijk is dat een bepaalde minderjarige van deze leeftijd de betreffende handeling zelfstandig verricht.</p>
      <p>
      Niet toegestaan gebruik van de Website <br/> De inhoud van de Website mag door de Gebruiker niet worden 
      gekopieerd, gereproduceerd en/of openbaar gemaakt worden.</p>
      <p>Het is de Gebruiker niet toegestaan om persoonsgegevens van Gebruikers die een Advertentie plaatsen 
        op de Website ("Adverteerders") te 
        verzamelen en/of Adverteerders te benaderen voor het aanbieden van eigen producten en/of diensten.</p>
        <p>
        Misbruik van de Website en de gevolgen hiervan <br/>Voor uw veiligheid en om misbruik tegen te gaan 
        worden e-mailadressen afgeschermd door EgyNL. Uw reactie op een Advertentie en eventuele 
        vervolgberichten tussen koper en adverteerder worden via de servers van EgyNL verzonden.
        </p>
        <p>
        Voor het melden van illegale en inbreuk makende advertenties, beledigende inhoud en andere problemen 
        verzoeken wij u gebruik te maken van de contant pagina; 
        zo kunnen wij er samen voor zorgen dat de site zo schoon en veilig mogelijk blijft. 
        Klachten omtrent oplichting kunt u melden via ons Contactformulier. 
        </p>
        <p>
        Indien wij aanwijzingen en/of klachten van andere Gebruikers ontvangen of om andere redenen van mening 
        zijn dat een bepaalde Gebruiker niet handelt in overeenstemming met de wet en/of de Gebruiksvoorwaarden 
        en/of de Privacyverklaring, dan kunnen wij de account van de betreffende gebruiker verwijderen.
        </p>
        <p>
        Wij geven geen garanties <br/> Wij kunnen u niet garanderen dat onze diensten altijd zullen voldoen aan 
        uw verwachtingen. Ook kunnen wij niet garanderen dat de Website foutloos functioneert en/of dat een 
        continue en/of veilige toegang tot de Website of delen daarvan kan worden verkregen.</p>

        <p>Alle informatie en getallen op de Website zijn onder voorbehoud van spel- of typefouten.
        </p>
        <p>
        Beperking aansprakelijkheid EgyNL <br/>
      Wij sluiten, voor zover wettelijk toegestaan is, aansprakelijkheid uit voor alle schade die een Gebruiker 
      lijdt door <br/>
(i) gebruik van de diensten van EgyNL;<br/>

(ii) het niet of niet veilig beschikbaar zijn van de Website of delen daarvan;<br/>

(iii) onjuiste informatie op de Website;<br/>

(iv) afname van diensten van derden of gebruik van gekochte producten via de Website; of<br/>

(v) wijzigingen in de diensten van EgyNL of wijzigingen in of op de Website.<br/>
        </p>
        <p>
        Wijzigingen van de diensten en de Website <br/>
EgyNL kan de Website of delen daarvan te alle tijden wijzigen. Tevens kunnen wij te alle tijden onze diensten wijzigen of beëindigen. Wij zullen ernaar streven om een dergelijke wijziging of beëindiging binnen een redelijke termijn aan te kondigen voordat deze wordt doorgevoerd. 
        </p>

    </div>
  )
}

export default Terms