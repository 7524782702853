import React from 'react'
// MUI
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const ResetPassword = ({handleChange, handleReset}) => {
  return (
    <div>
      <p>Vul uw emailadres in om uw wachtwoord te resetten.</p>
      <form style={{textAlign: 'center'}}
        onSubmit={handleReset}>
        <TextField required
        style={{maxWidth: '600px'}}
        id="resetEmail"
        name="email"
        type="email"
        label="Email"
        autoComplete='email'
        onChange={handleChange}
        fullWidth
        />
        <Button type="submit" 
          style={{margin: '20px auto', display: 'block'}}
          variant="contained" 
          color="primary">
          Versturen
        </Button>
      </form>
    </div>
  )
  }

export default ResetPassword
