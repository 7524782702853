import React from 'react'
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };
  return (
    <div className="page-container">
      {scrollToTop()}
        <Typography className="page-title" variant="h5">
          {t('overons')}
        </Typography>
        <div className="centered">
          <img 
            className="bot-banner" 
            src="/img/egynl-banner.webp"
            width="974"
            height="615"
            alt="EGYNL"
          />
        </div>
        <section style={{marginTop: 60}}>
          <p>
            {t('overons title')}
          </p>
           <p>{t('overons intro1')}</p>
            <p>{t('overons 1')}</p>
            <ul style={{ listStyleType: 'none' }}>
              <li>
              {t('overons li1')}
              </li>
              <li>
              {t('overons li2')}
              </li>
              <li>
              {t('overons li3')}
              </li>
              <li>
              {t('overons li4')}
              </li>
              <li>
              {t('overons li5')}
              </li>
              <li>
              {t('overons li6')}
              </li>
            </ul>
            <p>{t('overons 2')}</p>
            <p>{t('overons 3')}</p>
            <p>{t('overons 4')}</p>
            <p>{t('overons 5')}</p>
            <p>{t('overons 6')}</p>
            <p>{t('overons 7')}</p>
            <p>{t('overons end')}</p>
        </section>
    </div>
  )
}

export default About