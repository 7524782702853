import React, { useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
//COMPONENTS
import ContactUser from '../ads/ContactUser'
//ACTIONS
import {deleteMessage} from '../../store/actions/projectActions'
//MUI
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MessageIcon from '@material-ui/icons/Message';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

//called by MessageList
const MessageSummary = (props) => {
  const { t } = useTranslation();
  const [isContactShown, setContactShown] = useState(false);

  const handleContact = () => {
    isContactShown ? setContactShown(false) : setContactShown(true)
  }

  const handleDelete = () => {
    props.deleteMessage(props.message.id);
  }

  return (
    <Card className="project-card">
      <CardContent>
      <Typography component="h2" variant="h6">{t("bericht van")} {props.message.authorName}</Typography>
      <p>{props.message.message}</p>
      <Typography color="textSecondary" gutterBottom>{moment(props.message.sentAt.toDate()).format('DD/MM/YYYY')}
      </Typography>
      {isContactShown && <ContactUser props={props.message}/>} 
      <div style={{marginTop: 20}}>
        <Button 
          color={isContactShown ? "primary" : "secondary"} variant="outlined" 
          startIcon={isContactShown ? <CloseIcon /> : <MessageIcon />}
          onClick={handleContact}
          style={{margin: "10px"}}
        >
          {isContactShown ? t("annuleren") : t("beantwoorden")}
        </Button>  
        <Button style={{margin: "10px"}} color='secondary' variant="contained" 
        startIcon={<DeleteForeverIcon />}
        onClick={handleDelete}>
          {t("verwijderen")}
        </Button>  
      </div>
    </CardContent>
    </Card>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteMessage: (messageId) =>dispatch(deleteMessage(messageId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageSummary);