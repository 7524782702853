import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
//MUI
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

/**
 * Displays a single ad, without it's description
 * Mapped by AdsList
 */
const AdOverview = ({project}) => {

  const img = project.file ? project.file : "img/egynl-banner.webp";
  const {t} = useTranslation()

  return (
      <Card className="project-card">
        <div style={{margin: 'auto', textAlign:'center'}}>
            <img style={{  maxWidth: '100%' , height: 'auto', marginTop: 15, marginBottom: 15 }}src={img} alt="foto"/>
        </div>
        <CardContent>
          <Typography variant="h6" component="h2">{project.title}
          </Typography>
          <Typography color="textSecondary" gutterBottom style={{marginBottom: 20, marginTop: 20}}>
            {t("geplaatst door")} {project.authorName}
          </Typography>
          <Typography color="textSecondary" gutterBottom>{moment(project.createdAt.toDate()).format('DD/MM/YYYY')}
          </Typography>
        </CardContent>
      </Card>
  )
}

export default AdOverview
