import React from 'react' // , { Suspense }
import {Link} from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
//Components
import SelectCategory from './SelectCategory';
//ACTIONS
import { resetMsgBadge } from '../../store/actions/projectActions'
//MUI
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import TextAnimator from './TextAnimator'
import Button from '@material-ui/core/Button'
//icons


const useStyles = makeStyles((theme) => ({
  root: {
      margin: theme.spacing(1),
      textAlign: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  welcome: {
    marginTop: 20
  }
}));

const Home = (props) => {
const { auth, profile } = props;
const classes = useStyles();
const { t } = useTranslation();

const scrollToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
};

const welcome = auth.uid ? 
<div className="centered red">
  <Link  
    to="/profiel"
    style={{ textDecoration: 'none', color: 'inherit' }}
  >
    {t('welkom')}{profile.name}
  </Link>
  <Link to="/inbox">
    <Badge 
      overlap="rectangular" //overlap="rectangle" was deprecated warning fix
      badgeContent={profile.newMessages} 
      onClick={() => props.resetMsgBadge(auth.uid)}
      color="secondary" style={{margin:'0 10px'}}
    >
      <MailOutlineIcon color="action" />
    </Badge>
  </Link>
</div>
:
<div className="centered">
  <Button color="secondary" className={classes.button} variant="outlined" component={Link} to="/aanmelden">{t('aanmelden')}</Button>
  <Button color="secondary" className={classes.button} variant="outlined" component={Link} to="/inloggen">{t('inloggen')}</Button>
</div>;

  return (
    <div className="home">
      {scrollToTop()}
      <div className="banner-container">
        <TextAnimator/>
        <img className="banner" src='img/cairo.webp'
          width="1200" height="675" alt="banner"/>
      </div> 
      <div className={classes.welcome}>
        {welcome} 
      </div>
      <div className="page-container">
        <div style={{}}>
          <p>{t('intro1')}</p>  
          <p>{t('intro2')}</p>  
          <p>{t('intro3')}</p>
        </div>      
          <SelectCategory/>
          <div className="grid-centered" style={{marginBottom: 40, marginTop: 40}}>
            <a href="https://play.google.com/store/apps/details?id=nl.egynl.twa" target="_blank" rel="noreferrer">
              <img src="/img/google-play-badge.png" alt="play store" width="180"
              height="70" />
            </a>
          </div>
        <div className="bot-banner-container">
          <img 
            className="bot-banner"
            src="/img/egynl-banner.webp"
            width="974"
            height="615"
            alt="EGYNL"
          /> 
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return{
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetMsgBadge: (uid) => dispatch(resetMsgBadge(uid))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)

