import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

function AdCreated() {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    scrollToTop();
    const redirectTimer = setTimeout(() => {
      history.push('/');
    }, 2000);

    return () => clearTimeout(redirectTimer);
  }, [history]);

  return (
    <div className="page-container">
      <Typography className="page-title" variant="h5">
        Success
      </Typography>
      <Alert severity="success">
        {t('Uw advertentie verschijnt na goedkeuring op de site.')}
      </Alert>
    </div>
  );
}

export default AdCreated;
