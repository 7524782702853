import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import { useTranslation } from 'react-i18next';
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import withRoot from './withRoot';
//nav
import Navbar from './components/layout/Navbar'
import Footer from './components/layout/Footer'
//pages
import Search from './components/pages/Search'
import Dashboard from './components/dashboard/Dashboard'
import Home from './components/pages/Home'
import ProjectDetails from './components/dashboard/ProjectDetails'
import ApprovedAds from './components/ads/ApprovedAds'
import News from './components/ads/News'
import Jobs from './components/ads/Jobs'
import Market from './components/ads/Market'
import SignIn from './components/auth/SignIn'
import SignUp from './components/auth/SignUp'
import Contact from './components/pages/Contact'
import About from './components/pages/About'
import Terms from './components/pages/Terms'
import Profile from './components/user/Profile'
import AdCreated from './components/ads/AdCreated'
import Inbox from './components/user/Inbox'
import SplashScreen from './components/pages/SplashScreen'
//import ApprovedAdDetails from './components/ads/ApprovedAdDetails'
//import CreateAd from './components/ads/CreateAd'
//import UserAds from './components/user/UserAds'

const CreateAd = lazy(() => import('./components/ads/CreateAd'))
const ApprovedAdDetails = lazy(() => import('./components/ads/ApprovedAdDetails'))
const UserAds = lazy(() => import('./components/user/UserAds'))

const App = () => {
  const { i18n } = useTranslation();

  document.body.dir = i18n.dir();

  const theme = createTheme({
    palette: {
      primary: {
        light: '#fff',
        main: '#333',
        dark: '#000',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ce1126',
        main: '#AE1C28',
        dark: '#bf360c',
        contrastText: '#fff',
      },
      textPrimary: {
        main: '#c09300',
      },
    },
    direction: i18n.dir(),
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Suspense fallback={<SplashScreen />}>
        <Navbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/admin" component={Dashboard} />
            <Route exact path="/zoeken" component={Search} />
            <Route path="/admin/:id" component={ProjectDetails} />
            <Route path="/advertenties" component={ApprovedAds} />
            <Route path="/nieuws" component={News} />
            <Route path="/werk" component={Jobs} />
            <Route path="/verkoop" component={Market} />
            <Route path="/advertentie/:id" component={ApprovedAdDetails} />
            <Route path="/inloggen" component={SignIn} />
            <Route path="/aanmelden" component={SignUp} />
            <Route path="/advertentieplaatsen" component={CreateAd} />
            <Route path="/success" component={AdCreated} />
            <Route path="/contact" component={Contact} />
            <Route path="/overons" component={About} />
            <Route path="/voorwaarden-en-privacy" component={Terms} />
            <Route path="/profiel/" component={Profile} />
            <Route path="/mijnadvertenties" component={UserAds} />
            <Route path="/inbox" component={Inbox} />
            <Route path="/splash" component={SplashScreen} />
          </Switch>
        </Suspense>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default withRoot(App);