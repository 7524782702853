import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Work from '@material-ui/icons/Work';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import LocalLibrarySharpIcon from '@material-ui/icons/LocalLibrarySharp';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    textAlign: 'center',
  },
  card: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    minHeight: 50,
    textDecoration: 'none',
    color: '#fff',
    border: 'none',
  },
  workCard: {
    backgroundColor: '#AE1C28',
  },
  marketCard: {
    color: '#c09300',
  },
  newsCard: {
    backgroundColor: '#000',
  },
  actionArea: {
    minHeight: 50,
  },
  content: {
    verticalAlign: 'middle',
  },
}));

//The category cards used in Home and AdList
const CategoryCard = ({ to, icon, backgroundColor, textColor, label }) => {
  const classes = useStyles();

  return (
    <Card className={`${classes.card} ${backgroundColor}`} component={Link} to={to}>
      <CardActionArea className={classes.actionArea}>
        <CardContent className={classes.content}>
          {React.cloneElement(icon, { fontSize: 'large', color: 'inherit' })}
          <Typography variant="h6" style={{ color: textColor }}>
            {label}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const SelectCategory = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className="img-container" spacing={1}>
      <Grid item xs={4}>
        <CategoryCard
          to="/werk"
          icon={<Work />}
          backgroundColor={classes.workCard}
          label={t('werk')}
        />
      </Grid>
      <Grid item xs={4}>
        <CategoryCard
          to="/verkoop"
          icon={<ShoppingBasketIcon />}
          backgroundColor={classes.marketCard}
          textColor={classes.marketCard}
          label={t('markt')}
        />
      </Grid>
      <Grid item xs={4}>
        <CategoryCard
          to="/nieuws"
          icon={<LocalLibrarySharpIcon />}
          backgroundColor={classes.newsCard}
          label={t('nieuws')}
        />
      </Grid>
    </Grid>
  );
};

export default SelectCategory;
